import React from "react";
import PowerBiReport from "../Utils/Powerbi_client";

const PredictiveAnalyticsData = () => {
  return (
    <div className="dataBoard">
      {/* <div className="dataAnalyseBoard">
        <PowerBiReport
          reportId={process.env.REACT_APP_FORECASTING_REPORT_ID}
          embedUrl={process.env.REACT_APP_FORECASTING_EMBED_URL}
          accessToken={process.env.REACT_APP_ACCESS_TOKEN}
          pageNavigation={true}
        />
      </div> */}
      <iframe
        title="Predective Analytics"
        width="1200"
        height="600"
        src="https://app.powerbi.com/reportEmbed?reportId=9b685cb3-dc8b-4e73-aca1-4644c31eebd7&autoAuth=true&ctid=f8300747-02c3-470c-a3d6-5a3355e3d77d&filterPaneEnabled=false"
        frameborder="0"
      ></iframe>
    </div>
  );
};

export default PredictiveAnalyticsData;
