import React, { useEffect } from "react";
import SalesData from "../components/SalesData";
import { decoded } from "../Utils/UserProfile";
import { useNavigate } from "react-router";

const Sales = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (decoded === null) {
      window.location.href = "/";
    }
  }, [decoded]);
  return (
    <div className="innerContainer">
      <div className="dashboardPage">
        <SalesData />
      </div>
    </div>
  );
};

export default Sales;
