import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { runWithAdal } from "react-adal";
import { authContext } from "./config";

const DO_NOT_LOGIN = true;
const root = ReactDOM.createRoot(document.getElementById("root"));
runWithAdal(
  authContext,
  () => {
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  },
  DO_NOT_LOGIN
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
