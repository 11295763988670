import { getToken } from "../config";
import jwt_decode from "jwt-decode";

export const decoded = getToken()
  ? jwt_decode(
      localStorage.getItem(
        `adal.access.token.key${process.env.REACT_APP_AZURE_AD_CLIENT_ID}`
      )
    )
  : null;
