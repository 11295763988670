import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import CircularProgress from "@mui/material/CircularProgress";

const PowerBiReport = ({ reportId, embedUrl, accessToken, pageNavigation }) => {
  const [showLoader, setShowLoader] = useState(true);

  return (
    <section className="App">
      {showLoader && (
        <div className="loader-container">
          <div className="loader">
            <CircularProgress />
          </div>
        </div>
      )}
      <section id="bi-report">
        <PowerBIEmbed
          embedConfig={{
            type: "report", // Since we are reporting a BI report, set the type to report
            id: reportId, // Add the report Id here
            embedUrl: embedUrl, // Add the embed url here
            accessToken: accessToken,
            tokenType: models.TokenType.Aad, // Since we are using an Azure Active Directory access token, set the token type to Aad
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
                pageNavigation: {
                  visible: pageNavigation,
                },
              },
              background: models.BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                  setShowLoader(false);
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                  setShowLoader(false);
                },
              ],
              ["visualClicked", () => console.log("visual clicked")],
              ["pageChanged", (event) => console.log(event)],
            ])
          }
          cssClassName="powerbi-report"
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport; // save report in window object
          }}
        />
      </section>
    </section>
  );
};

export default PowerBiReport;
