import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
// import { config } from "./config";
import Dashboard from "./Pages/Dashboard";
import PageDataView from "./Pages/PageDataView";
import Appointments from "./Pages/Appointments";
import Pharma from "./Pages/Pharma";
import Diagnostics from "./Pages/Diagnostics";
import Sales from "./Pages/Sales";
import Marketing from "./Pages/Marketing";
import Delta from "./Pages/Delta";
import PredictiveAnalytics from "./Pages/PredictiveAnalytics";
import DoctorAnalytics from "./Pages/DoctorAnalytics";
import PatientInsights from "./Pages/PatientInsights";
import EnterpriseChat from "./Pages/EnterpriseChat";
import Sidenav from "./components/Sidenav";
import Footer from "./components/Footer";
import Prediction from "./Pages/Prediction";

function App() {
  return (
    <BrowserRouter>
      <Sidenav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/PatientDataView" element={<PageDataView />} />
        <Route path="/appointmentreport" element={<Appointments />} />
        <Route path="/pharmaorders" element={<Pharma />} />
        <Route path="/diagnosticorders" element={<Diagnostics />} />
        <Route path="/sales" element={<Sales />} />
        <Route path="/marketing" element={<Marketing />} />
        <Route path="/Delta" element={<Delta />} />
        <Route path="/predictiveAnalytics" element={<PredictiveAnalytics />} />
        <Route path="/doctorAnalytics" element={<DoctorAnalytics />} />
        <Route path="/patientInsights" element={<PatientInsights />} />
        <Route path="/enterprisechat" element={<EnterpriseChat />} />
        <Route path="/prediction" element={<Prediction />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
 
export default App;
