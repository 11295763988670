import React, { useState, useEffect } from "react";
import PowerBiReport from "../Utils/Powerbi_client";
import { useLocation } from "react-router-dom";

const PharmaData = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const appointmentId = queryParams.get("id");
  const [height, setHeight] = useState(600);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHeight(1100);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="dataBoard">
      <div className="dataAnalyseBoard">
        {/* <PowerBiReport
          reportId={process.env.REACT_APP_PHARMA_REPORT_ID}
          embedUrl={`${process.env.REACT_APP_PHARMA_EMBED_URL}${appointmentId}`}
          accessToken={process.env.REACT_APP_ACCESS_TOKEN}
          pageNavigation={false}
        /> */}

        <iframe
          title="Pharma"
          width="1200"
          height={height}
          src="https://app.powerbi.com/reportEmbed?reportId=bc7844ed-ca35-4554-a7c6-3941dd6a4235&autoAuth=true&ctid=f8300747-02c3-470c-a3d6-5a3355e3d77d&navContentPaneEnabled=false&filterPaneEnabled=false"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default PharmaData;
