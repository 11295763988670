import React, { useEffect } from "react";
import Link from "@mui/material/Link";
import ArrowRight from "../assets/images/ArrowRight.svg";
import { useNavigate } from "react-router";
import UserRoles from "../Json/roles.json";
import { adalConfig } from "../config";
import { AuthenticationContext } from "react-adal";
import { decoded } from "../Utils/UserProfile";

const Home = () => {
  const navigate = useNavigate();
  const handleLoginClick = () => {
    const authContext = new AuthenticationContext(adalConfig);
    authContext.login();
  };

  useEffect(() => {
    // let userDefault;
    // UserRoles?.map((user) => {
    //   if (user?.user === decoded?.unique_name) {
    //     userDefault = user?.default;
    //   }
    // });
    // if (decoded && userDefault) {
    //   navigate(userDefault);
    // } else
    if (decoded) {
      navigate("/dashboard");
    }
  }, [decoded]);
  return (
    <div className="homePage">
      <div className="container">
        <div className="homePageBanner">
          <div className="homePageContent">
            <h1>AI-Driven, Data Management Platform</h1>
            <h2>Powered by Accellor</h2>
            <p>
              Bring all your data from different sources and manage them in a
              unified controlled environment
            </p>
          </div>
          <div className="homePagelogin">
            <h4>Sign in</h4>
            <h5>Signin with your Microsoft 365 credentials</h5>
            <Link onClick={() => handleLoginClick()}>
              Sign in <img src={ArrowRight} alt="ArrowRight" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
