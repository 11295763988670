import React, { useEffect } from "react";
import MarketingData from "../components/MarketingData";
import { decoded } from "../Utils/UserProfile";
import { useNavigate } from "react-router";

const Marketing = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (decoded === null) {
      window.location.href = "/";
    }
  }, [decoded]);
  return (
    <div className="innerContainer">
      <div className="dashboardPage marketingPage">
        <MarketingData />
      </div>
    </div>
  );
};

export default Marketing;
