import { Button, List, ListItem, ListItemButton } from "@mui/material";
import React, { useEffect } from "react";
import logo from "../assets/images/logo.svg";
import dashboardLogo from "../assets/images/dashboard.svg";
import dashboardLogoActive from "../assets/images/dashboard-active.svg";
import navLogo from "../assets/images/nav-logo.svg";
import navLogoActive from "../assets/images/nav-logo-active.svg";
import { useLocation, useNavigate } from "react-router";
import UserRoles from "../Json/roles.json";
import { decoded } from "../Utils/UserProfile";
import { adalConfig, authContext } from "../config";
import { AuthenticationContext } from "react-adal";
import { useState } from "react";
import { checkTokenExpiry, RefreshToken } from "../Utils/TokenRefresh";
import { useHistory } from "react-router-dom";

function Sidenav() {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedMarketingIndex, setSelectedMarketingIndex] = useState(0);
  const [selectedAnayticsIndex, setSelectedAnalyticsIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleMarketingListItemClick = (event, index) => {
    setSelectedMarketingIndex(index);
  };

  const handleAnalyticsListItemClick = (event, index) => {
    setSelectedAnalyticsIndex(index);
  };

  useEffect(() => {
    if (location.pathname === "/marketing") {
      setSelectedMarketingIndex(0);
    }
  }, [location.pathname]);

  const refreshTokenWithNavigation = async () => {
    try {
      // Perform token refresh logic here

      // For example:
      await RefreshToken();

      // Check if the token is expired
      const isTokenExpired = checkTokenExpiry();

      if (isTokenExpired) {
        window.location.href = "/";
      }
    } catch (error) {
      // Handle token refresh error
      console.error("Token refresh failed:", error);
    }
  };

  useEffect(() => {
    const cachedUser = authContext.getCachedUser();

    if (!cachedUser && location.pathname !== "/") {
      // No cached user, redirect to the home page
      window.location.href = "/";
    } else {
      const tokenRefreshInterval = setInterval(() => {
        refreshTokenWithNavigation();
      }, 1200000); // Refresh token every 20 minutes

      const tokenExpiryCheckInterval = setInterval(() => {
        checkTokenExpiry();
      }, 600000); // Check token expiry every 10 minutes

      return () => {
        clearInterval(tokenRefreshInterval);
        clearInterval(tokenExpiryCheckInterval);
      };
    }
  }, [authContext, location.pathname]);

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setSelectedIndex(0);
    } else if (location.pathname === "/sales") {
      setSelectedIndex(1);
    } else if (
      location.pathname === "/marketing" ||
      location.pathname === "/doctorAnalytics" ||
      location.pathname === "/patientInsights"
    ) {
      setSelectedIndex(2);
    } else if (
      location.pathname === "/predictiveAnalytics" ||
      location.pathname === "/prediction" ||
      location.pathname === "/forcasting"
    ) {
      setSelectedIndex(3);
    } else if (location.pathname === "/enterprisechat") {
      setSelectedIndex(4);
    } else setSelectedIndex(0);
  }, [location.pathname, selectedIndex]);

  const words = decoded?.name.split(" ");

  const firstWord = words?.[0] || "";
  const secondWord = words?.[1] || "";

  const firstInitial = firstWord && firstWord.charAt(0).toUpperCase();
  const secondInitial = secondWord && secondWord.charAt(0).toUpperCase();

  const handleLogout = () => {
    const authContext = new AuthenticationContext(adalConfig);
    authContext.clearCache();
    authContext.logOut();
  };

  const UserRole = UserRoles?.filter((user) => {
    if (user?.user === decoded?.unique_name) {
      return user;
    }
  });

  // useEffect(() => {
  //   if (
  //     UserRole?.[0]?.role === "dataengineer" &&
  //     location.pathname === "/marketing"
  //   ) {
  //     navigate("/sales");
  //   } else if (
  //     UserRole?.[0]?.role === "datasteward" &&
  //     (location.pathname === "/sales" ||
  //       location.pathname === "/predictiveAnalytics")
  //   ) {
  //     navigate("/marketing");
  //   } else if (
  //     UserRole?.[0]?.role !== "dataengineer" &&
  //     UserRole?.[0]?.role !== "datasteward" &&
  //     UserRole?.[0]?.role !== "businessowner"
  //   ) {
  //     navigate("/dashboard");
  //   }
  // }, []);

  return (
    <>
      <div className="sideNav">
        {decoded && (
          <div className="innerContainer">
            <div className="headerTop">
              <div className="sidenavLogo">
                <img src={logo} alt="logo" />
              </div>

              <div className="sidenavLogout">
                <div className="sidenavLogoutIn">
                  <div className="navUserLogo">{`${firstInitial}${secondInitial}`}</div>
                  <div className="navuserDtls">
                    <h4>{decoded?.name}</h4>
                    <h5>{UserRole?.[0]?.displayrole}</h5>
                    <Button onClick={() => handleLogout()}>Logout</Button>
                  </div>
                </div>
              </div>
            </div>
            <List>
              <ListItem>
                <ListItemButton
                  selected={selectedIndex === 0}
                  onClick={(event) => {
                    handleListItemClick(event, 0);
                    navigate("/dashboard");
                  }}
                >
                  <img src={dashboardLogo} alt="dashboardLogo" />
                  <img src={dashboardLogoActive} alt="dashboardLogo" />
                  Dashboard
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  selected={selectedIndex === 1}
                  onClick={(event) => {
                    handleListItemClick(event, 1);
                    navigate("/sales");
                  }}
                  // disabled={
                  //   UserRole?.[0]?.role === "datasteward" ||
                  //   (UserRole?.[0]?.role !== "businessowner" &&
                  //     UserRole?.[0]?.role !== "dataowner" &&
                  //     UserRole?.[0]?.role !== "dataengineer")
                  // }
                >
                  <img src={navLogo} alt="dashboardLogo" />
                  <img src={navLogoActive} alt="navLogoActive" />
                  Sales
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  selected={selectedIndex === 2}
                  onClick={(event) => {
                    handleListItemClick(event, 2);
                    navigate("/marketing");
                  }}
                  // disabled={
                  //   UserRole?.[0]?.role === "dataengineer" ||
                  //   (UserRole?.[0]?.role !== "businessowner" &&
                  //     UserRole?.[0]?.role !== "dataowner" &&
                  //     UserRole?.[0]?.role !== "datasteward")
                  // }
                >
                  <img src={navLogo} alt="dashboardLogo" />
                  <img src={navLogoActive} alt="navLogoActive" />
                  Marketing
                </ListItemButton>
              </ListItem>

              <ListItem>
                <ListItemButton
                  selected={selectedIndex === 3}
                  onClick={(event) => {
                    handleListItemClick(event, 3);
                    navigate("/predictiveAnalytics");
                  }}
                  // disabled={
                  //   UserRole?.[0]?.role === "datasteward" ||
                  //   (UserRole?.[0]?.role !== "businessowner" &&
                  //     UserRole?.[0]?.role !== "dataowner" &&
                  //     UserRole?.[0]?.role !== "dataengineer")
                  // }
                >
                  <img src={navLogo} alt="dashboardLogo" />
                  <img src={navLogoActive} alt="navLogoActive" />
                  Analytics
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  selected={selectedIndex === 4}
                  onClick={(event) => {
                    handleListItemClick(event, 4);
                    navigate("/enterprisechat");
                  }}
                >
                  <img src={navLogo} alt="dashboardLogo" />
                  <img src={navLogoActive} alt="navLogoActive" />
                  Enterprise Chat
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        )}
      </div>
      {(location.pathname === "/marketing" ||
        location.pathname === "/doctorAnalytics" ||
        location.pathname === "/patientInsights") && (
        <div className="sideNav subMenu">
          <div className="innerContainer">
            <List>
              <ListItem>
                <ListItemButton
                  selected={selectedMarketingIndex === 1}
                  onClick={(event) => {
                    handleMarketingListItemClick(event, 1);
                    navigate("/patientInsights");
                  }}
                >
                  Patient Insights
                </ListItemButton>
                <ListItemButton
                  selected={selectedMarketingIndex === 2}
                  onClick={(event) => {
                    handleMarketingListItemClick(event, 2);
                    navigate("/doctorAnalytics");
                  }}
                >
                  Doctor Analytics
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        </div>
      )}
      {(location.pathname === "/predictiveAnalytics" ||
        location.pathname === "/forcasting" ||
        location.pathname === "/prediction") && (
        <div className="sideNav subMenu">
          <div className="innerContainer">
            <List>
              <ListItem>
                <ListItemButton
                  selected={selectedAnayticsIndex === 0}
                  onClick={(event) => {
                    handleAnalyticsListItemClick(event, 0);
                    navigate("/predictiveAnalytics");
                  }}
                >
                  Forecast
                </ListItemButton>
                <ListItemButton
                  selected={selectedAnayticsIndex === 1}
                  onClick={(event) => {
                    handleAnalyticsListItemClick(event, 1);
                    navigate("/prediction");
                  }}
                >
                  Predictive
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        </div>
      )}
    </>
  );
}

export default Sidenav;
