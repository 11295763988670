import React from "react";
import PowerBiReport from "../Utils/Powerbi_client";

const DeltaData = () => {
  return (
    <div className="dataBoard">
      <div className="dataAnalyseBoard">
        {/* <PowerBiReport
          reportId={process.env.REACT_APP_DELTA_REPORT_ID}
          embedUrl={process.env.REACT_APP_DELTA_EMBED_URL}
          accessToken={process.env.REACT_APP_ACCESS_TOKEN}
          pageNavigation={false}
        /> */}

        <iframe
          title="Delta patients"
          width="1200"
          height="800"
          src="https://app.powerbi.com/reportEmbed?reportId=86debc51-0cfd-4faa-825f-560d9907675b&autoAuth=true&ctid=f8300747-02c3-470c-a3d6-5a3355e3d77d&navContentPaneEnabled=false&filterPaneEnabled=false"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default DeltaData;
