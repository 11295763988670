import React, { useState, useEffect } from "react";
import PowerBiReport from "../Utils/Powerbi_client";
import { useLocation } from "react-router-dom";

const AppointmentsData = () => {
  const [height, setHeight] = useState(600);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHeight(800);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const appointmentId = queryParams.get("id");
  return (
    <div className="dataBoard">
      <div className="dataAnalyseBoard">
        {/* <PowerBiReport
          reportId={process.env.REACT_APP_APPOINTMENTS_REPORT_ID}
          embedUrl={`${process.env.REACT_APP_APPOINTMENTS_EMBED_URL}${appointmentId}`}
          accessToken={process.env.REACT_APP_ACCESS_TOKEN}
          pageNavigation={false}
        /> */}
        <iframe
          title="Appointments"
          width="1200"
          height={height}
          src="https://app.powerbi.com/reportEmbed?reportId=79b34aa6-2b7d-4a89-abcc-29b00bc0d2f7&autoAuth=true&ctid=f8300747-02c3-470c-a3d6-5a3355e3d77d&navContentPaneEnabled=false&filterPaneEnabled=false"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default AppointmentsData;
