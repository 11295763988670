import React, { useState, useEffect } from "react";

const PredictionData = () => {
  return (
    <div className="dataBoard">
      <div>
        <iframe
          title="readmission report"
          width="1140"
          height="541.25"
          src="
https://app.powerbi.com/reportEmbed?reportId=14a99770-1f88-4fdd-8d9d-651479aa24fa&autoAuth=true&ctid=f8300747-02c3-470c-a3d6-5a3355e3d77d&navContentPaneEnabled=false&filterPaneEnabled=false"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default PredictionData;
