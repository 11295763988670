import React, { useState, useEffect } from "react";
import PowerBiReport from "../Utils/Powerbi_client";

const MarketingData = () => {
  const [height, setHeight] = useState(600);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHeight(1400);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <div className="dataBoard">
      {/* <div className="dataAnalyseBoard">
        <PowerBiReport
          reportId={process.env.REACT_APP_OPERATIONS_REPORT_ID}
          embedUrl={process.env.REACT_APP_OPERATIONS_EMBED_URL}
          accessToken={process.env.REACT_APP_ACCESS_TOKEN}
          pageNavigation={false}
        />
      </div> */}
      <div>
        <iframe
          title="Marketing"
          width="1200"
          height={height}
          src="https://app.powerbi.com/reportEmbed?reportId=24eaca05-ce8d-4b44-b44a-ca961a877a5a&autoAuth=true&ctid=f8300747-02c3-470c-a3d6-5a3355e3d77d&navContentPaneEnabled=false&filterPaneEnabled=false"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default MarketingData;
