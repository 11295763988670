import React, { useState, useEffect } from "react";
import PowerBiReport from "../Utils/Powerbi_client";

const SalesData = () => {
  const [height, setHeight] = useState(600);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHeight(1700);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <div className="dataBoard">
      <div className="dataAnalyseBoard">
        {/* <PowerBiReport
          reportId={process.env.REACT_APP_SALES_REPORT_ID}
          embedUrl={process.env.REACT_APP_SALES_EMBED_URL}
          accessToken={process.env.REACT_APP_ACCESS_TOKEN}
          pageNavigation={false}
        /> */}
        <iframe
          title="Sales"
          width="1200"
          height={height}
          src="https://app.powerbi.com/reportEmbed?reportId=3bdee31b-ff07-4b98-b3ea-d2e773bdb2cc&autoAuth=true&ctid=f8300747-02c3-470c-a3d6-5a3355e3d77d&navContentPaneEnabled=false&filterPaneEnabled=false"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default SalesData;
