import React from "react";

function Footer() {
  return (
    <div className="footer">
      <p>© 2024 Accellor. All rights reserved</p>
    </div>
  );
}

export default Footer;
