import React, { useState, useRef, useEffect } from "react";
import send from "../assets/images/send.svg";
import loader from "../assets/images/loader.gif";
import { Button, TextField } from "@mui/material";
import parse from "html-react-parser";
import logo from "../assets/images/chat-logo.png";

const ChatGptData = () => {
  const [inputValue, setInputValue] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [loadingText, setLoadingText] = useState("Working on response...");
  const bottomRef = useRef(null);

  const AdalIdToken = localStorage.getItem("adal.idtoken");
  const fetchData = async (question) => {
    setShowLoader(true);
    try {
      const apiResponse = await fetch(
        `${process.env.REACT_APP_ENTERPRISE_CHAT_API}${encodeURIComponent(
          question
        )}`,
        {
          method: "GET",
          headers: {
            Authorization: process.env.REACT_APP_ENTERPRISE_CHAT_BEARER_TOKEN,
            "Content-Type": "application/json",
            azuretoken: AdalIdToken,
          },
        }
      );
      const data = await apiResponse.json();
      if (data) {
        setShowLoader(false);
      }

      const newChat = {
        question,
        answer: data?.answer?.length > 0 && data?.answer,
        columns: data?.columns,
        isTable: data?.isTable,
        json: data?.json,
        queryResult: data?.queryResult,
        tableHeader: data?.table_header,
      };

      setChatHistory([...chatHistory, newChat]);
      setInputValue("");
    } catch (error) {
      console.error("Error fetching data:", error);
      setChatHistory([
        ...chatHistory,
        { question, answer: "Error fetching data" },
      ]);
      setShowLoader(false);
      setInputValue("");
    }
  };

  const handleButtonClick = () => {
    if (inputValue.trim() !== "") {
      fetchData(parse(inputValue));
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      fetchData(parse(inputValue));
    }
  };

  const convertToTitleCase = (input) => {
    const words = input.split(/(?=[A-Z])/);

    const titleCaseWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    const titleCaseString = titleCaseWords.join(" ");

    return titleCaseString;
  };

  const TableComponent = ({ data, columns }) => {
    return (
      <table>
        <thead>
          <tr>
            {columns?.map((columnName, index) => (
              <th key={index}>
                {convertToTitleCase(
                  columnName
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {columns?.map((column, columnIndex) => {
                  let value = item[column];

                  if (Array.isArray(value)) {
                    const isDateArray =
                      value.indexOf("date") > -1 &&
                      (value.length === 8 ||
                        value.length === 7 ||
                        value.length === 4);
                    value = isDateArray
                      ? `${value[2]}/${value[1]}/${value[0]}`
                      : value.map((arrayItem) => (
                          <div key={arrayItem}>{arrayItem}</div>
                        ));
                  }

                  if (Array.isArray(value)) {
                    value = value.map((arrayItem) => (
                      <div key={arrayItem}>{arrayItem}</div>
                    ));
                  }

                  const formattedValue =
                    (column === "estimatedAmount" ||
                      column === "revenue" ||
                      column === "totalPrice") &&
                    !isNaN(parseFloat(value))
                      ? new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          useGrouping: true,
                        }).format(parseFloat(value))
                      : value;

                  return <td key={columnIndex}>{formattedValue}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView();
  }, [showLoader, chatHistory]);

  useEffect(() => {
    let timeout;

    if (showLoader) {
      timeout = setTimeout(() => {
        setLoadingText("Hang on! almost there...");
      }, 6000);
    } else {
      setLoadingText("Working on response...");
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [showLoader]);

  return (
    <div className="dataBoard">
      <div className="dataAnalyseBoard">
        <div className="chatdiv">
          <div className="chatHistorySidebar">
            <div className="chatHistory">
              <ul>
                {chatHistory?.map((question, index) => (
                  <li key={index}>{question?.question}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="chatMainBox">
            <div className="chatBoxWrap">
              {chatHistory?.length === 0 && (
                <div className="chatInitial">
                  <div className="chatInitialIn">
                    <img src={logo} alt="logo" />
                    <h2>Chat with Enterprise Data</h2>
                  </div>
                  <div className="ChatPromptBtns">
                    <Button
                      onClick={() => {
                        fetchData(parse("Top 10 doctors with highest revenue"));
                        setInputValue("Top 10 doctors with highest revenue");
                      }}
                      disabled={showLoader}
                    >
                      Top 10 doctors with highest revenue
                    </Button>
                    <Button
                      onClick={() => {
                        fetchData(parse("Show me the summary of Jenna Crooks"));
                        setInputValue("Show me the summary of Jenna Crooks");
                      }}
                      disabled={showLoader}
                    >
                      Show me the summary of Jenna Crooks
                    </Button>
                    <Button
                      onClick={() => {
                        fetchData(parse("Get me list of specializations"));
                        setInputValue("Get me list of specializations");
                      }}
                      disabled={showLoader}
                    >
                      Get me list of specializations
                    </Button>
                    <Button
                      onClick={() => {
                        fetchData(parse("Get me list of orthopedics doctors"));
                        setInputValue("Get me list of orthopedics doctors");
                      }}
                      disabled={showLoader}
                    >
                      Get me list of orthopedics doctors
                    </Button>
                  </div>
                </div>
              )}

              {chatHistory?.map((chat, index) => (
                <ul>
                  <>
                    <div className="chatsent chatQ">
                      <span>Q</span>
                      <p>{parse(chat?.question)}</p>
                    </div>
                    <div className="chatsent chatAns">
                      {chat?.answer && chat?.answer !== "" && (
                        <>
                          <span>A</span>
                          <p>
                            {chat?.answer?.split("\n").map((point, index) => (
                              <p key={index}>{parse(point.trim())}</p>
                            ))}
                          </p>
                        </>
                      )}

                      {(chat?.answer === null || chat?.answer == "") &&
                        !(chat.json.length > 0) && (
                          <>
                            <span>A</span>
                            <p>
                              Sorry, I am not able to get you the information
                              you are looking for.. Try the question in a
                              different way!
                            </p>
                          </>
                        )}
                      {chat?.isTable &&
                        chat?.json !== null &&
                        chat?.json?.length > 0 && (
                          <div className="chatBoxTable">
                            <p style={{ marginBottom: "10px" }}>
                              {chat?.tableHeader}
                            </p>
                            <TableComponent
                              data={chat?.json}
                              columns={chat?.columns}
                            />
                          </div>
                        )}
                    </div>
                    <div ref={bottomRef} className="refDiv"></div>
                  </>
                </ul>
              ))}
            </div>

            <div className="chatBoxEnter">
              {showLoader && (
                <div className="loaderText">
                  {loadingText}
                  <div>
                    <img src={loader} id="loader" alt="loader" />
                  </div>
                </div>
              )}
              <TextField
                placeholder="Ask me anything"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                disabled={showLoader}
              />
              <Button onClick={handleButtonClick}>
                <img src={send} alt="send" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatGptData;
