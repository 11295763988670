// authService.js
import { authContext, adalConfig } from "../config";

const RefreshToken = () => {
  authContext.acquireToken(adalConfig.endpoints.api, (error, token) => {
    if (error || !token) {
      console.log("Token error", error);
    } else {
      console.log("Token refreshed:", token);
    }
  });
};

const checkTokenExpiry = () => {
  const expiresOn = authContext.getCachedToken(
    adalConfig.endpoints.api
  )?.expiresOn;

  const currentTime = new Date().getTime() / 1000;

  if (expiresOn && expiresOn - currentTime < 300) {
    RefreshToken();
  }
};

export { RefreshToken, checkTokenExpiry };
