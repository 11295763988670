import React, { useEffect } from "react";
import { decoded } from "../Utils/UserProfile";
import { useNavigate } from "react-router";
import AppointmentsData from "../components/AppointmentsData";

const Appointments = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (decoded === null) {
      window.location.href = "/";
    }
  }, [decoded]);
  return (
    <div className="innerContainer">
      <div className="dashboardPage">
        <AppointmentsData />
      </div>
    </div>
  );
};

export default Appointments;
