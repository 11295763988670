import React from "react";
import PowerBiReport from "../Utils/Powerbi_client";

const PatientInsightsData = () => {
  return (
    <div className="dataBoard">
      {/* <div className="dataAnalyseBoard">
        <PowerBiReport
          reportId={process.env.REACT_APP_PATIENT_INSIGHTS_REPORT_ID}
          embedUrl={process.env.REACT_APP_PATIENT_INSIGHTS_EMBED_URL}
          accessToken={process.env.REACT_APP_ACCESS_TOKEN}
          pageNavigation={false}
        />
      </div> */}
      <div>
        {/* Your embedded Power BI report */}
        <iframe
          title="Patient Insights"
          width="1200"
          height="600"
          src="https://app.powerbi.com/reportEmbed?reportId=95694d6c-72d3-4e64-b807-bfa237b24a15&autoAuth=true&ctid=f8300747-02c3-470c-a3d6-5a3355e3d77d&navContentPaneEnabled=false&filterPaneEnabled=false"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default PatientInsightsData;
