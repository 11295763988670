import React from "react";
import PowerBiReport from "../Utils/Powerbi_client";
import { useLocation } from "react-router-dom";

const DiagnosticsData = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const appointmentId = queryParams.get("id");
  return (
    <div className="dataBoard">
      <div className="dataAnalyseBoard">
        {/* <PowerBiReport
          reportId={process.env.REACT_APP_DIAGNOSTICS_REPORT_ID}
          embedUrl={`${process.env.REACT_APP_DIAGNOSTICS_EMBED_URL}${appointmentId}`}
          accessToken={process.env.REACT_APP_ACCESS_TOKEN}
          pageNavigation={false}
        /> */}
        <iframe
          title="Diagnostics"
          width="1200"
          height="600"
          src="https://app.powerbi.com/reportEmbed?reportId=da6f7917-a37e-43f9-b4b4-29267be17393&autoAuth=true&ctid=f8300747-02c3-470c-a3d6-5a3355e3d77d&navContentPaneEnabled=false&filterPaneEnabled=false"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default DiagnosticsData;
