import React, { useEffect } from "react";
import DashboardData from "../components/DashboardData";
import { decoded } from "../Utils/UserProfile";
import { useNavigate } from "react-router";

const Dashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (decoded === null) {
      window.location.href = "/";
    }
  }, [decoded]);

  return (
    <div className="innerContainer">
      <div className="dashboardPage">
        <DashboardData />
      </div>
    </div>
  );
};

export default Dashboard;
