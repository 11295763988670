import React, { useState, useEffect } from "react";
import PowerBiReport from "../Utils/Powerbi_client";

const DoctorAnalyticsData = () => {
  const [height, setHeight] = useState(600);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHeight(1100);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <div className="dataBoard">
      {/* <div className="dataAnalyseBoard">
        <PowerBiReport
          reportId={process.env.REACT_APP_DOCTOR_ANALYTICS_REPORT_ID}
          embedUrl={process.env.REACT_APP_DOCTOR_ANALYTICS_EMBED_URL}
          accessToken={process.env.REACT_APP_ACCESS_TOKEN}
          pageNavigation={false}
        />
      </div> */}
      <div>
        {/* Your embedded Power BI report */}
        <iframe
          title="Doctor Analytics"
          width="1200"
          height={height}
          src="https://app.powerbi.com/reportEmbed?reportId=4fc62feb-4e25-4a8c-b8e0-2d28ca58c7b6&autoAuth=true&ctid=f8300747-02c3-470c-a3d6-5a3355e3d77d&navContentPaneEnabled=false&filterPaneEnabled=false"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default DoctorAnalyticsData;
