// export const config = {
//   auth: {
//     clientId: "586c34aa-d82d-46d2-953c-fb61b5f2408d",
//     redirectUri: "http://localhost:3000",
//     scopes: ["user.read"],
//     authority:
//       "https://login.microsoftonline.com/f8300747-02c3-470c-a3d6-5a3355e3d77d",
//   },
//   cache: {
//     cacheLocation: "localStorage",
//     storeAuthStateInCookie: false,
//   },
// };

import { AuthenticationContext } from "react-adal";

export const adalConfig = {
  clientId: "586c34aa-d82d-46d2-953c-fb61b5f2408d",
  endpoints: {
    api: "586c34aa-d82d-46d2-953c-fb61b5f2408d",
  },
  cacheLocation: "localStorage",
  postLogoutRedirectUri: "https://devdata.azurewebsites.net/",
  tenant: "f8300747-02c3-470c-a3d6-5a3355e3d77d",
};
export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => authContext.getCachedToken(adalConfig.clientId);
