import React, { useEffect } from "react";
import PowerBiReport from "../Utils/Powerbi_client";
import { useState } from "react";

const DashboardData = () => {
  const [height, setHeight] = useState(600);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHeight(1200);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="dataBoard">
      <div className="dataAnalyseBoard">
        {/* <PowerBiReport
          reportId={process.env.REACT_APP_UNIFIED_DATA_REPORT_ID}
          embedUrl={process.env.REACT_APP_UNIFIED_DATA_EMBED_URL}
          accessToken={process.env.REACT_APP_ACCESS_TOKEN}
          pageNavigation={false}
        /> */}
        <iframe
          title="Appointments"
          width="1200"
          height={height}
          src="https://app.powerbi.com/reportEmbed?reportId=6f1da631-1f0a-42c6-b6f8-594232d7de81&autoAuth=true&ctid=f8300747-02c3-470c-a3d6-5a3355e3d77d&navContentPaneEnabled=false&filterPaneEnabled=false"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default DashboardData;
